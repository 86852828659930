export function useRouteName() {
    const normalizeRouteName = (routeName) => {
        if (typeof routeName === "string") return routeName.replace('___en', '').replace('___ru', '')
        return ""
    }

    const routeName = () => {
        const route = useRoute()
        
        return normalizeRouteName(route.name)
    }

    return { routeName, normalizeRouteName }
}