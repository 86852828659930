import { defineNuxtPlugin } from '#app';
import { message, Skeleton, Dropdown, DropdownButton, Spin, Menu, SkeletonInput } from "ant-design-vue";
import type { MessageApi } from "ant-design-vue/es/message";
import "ant-design-vue/es/skeleton/style/index.css"

export default defineNuxtPlugin((nuxtApp) => {
    message.config({ maxCount: 3 });
    nuxtApp.vueApp.component('a-skeleton', Skeleton)
    nuxtApp.vueApp.component('a-skeleton-input', SkeletonInput)
    nuxtApp.vueApp.component('a-dropdown', Dropdown)
    nuxtApp.vueApp.component('a-dropdown-button', DropdownButton)
    nuxtApp.vueApp.component('a-spin', Spin)
    nuxtApp.vueApp.component('a-menu', Menu)

    return {
        provide: {
            message: message as MessageApi
        }
    }
});