<template>
  <div class="single-check">
    <label class="check" :class="classList">
      <input
        class="check__input visually-hidden"
        type="checkbox"
        v-model="computedValue"
        v-bind="bindAttrs"
        :value="value" />
      <span class="check__box">
        <icon class="check__icon" name="check-checkbox" />
      </span>
      <slot name="default" v-if="hasDefaultSlot" />
      <template v-else>{{ label }}</template>
    </label>
  </div>
</template>

<script>
import Icon from "../Icon";

export default {
  name: "SingleCheckbox",
  components: {Icon},
  props: {
    modelValue: {
      type: [Array, Boolean, String],
    },
    label: {
      type: String,
    },
    size: {
      type: String,
      default: 'default',
    },
    children: {
      type: Array,
      default: () => {
        return []
      }
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    classList() {
      return {
        [`check--${this.size}`]: true,
        'check--disabled': this.disabled
      }
    },
    bindAttrs() {
      const attrs = {}
      if (this.disabled) attrs.disabled = true
      return attrs
    },
    hasDefaultSlot() {
      return !!this.$slots['default']
    }
  },
  methods: {
    onChange($event) {
      this.$emit('update:modelValue', this.modelValue)
    }
  }
}
</script>

<style scoped lang="scss">
.check {
  font-size             : rem-calc(16);
  display               : grid;
  grid-template-columns : rem-calc(18) auto;
  gap                   : rem-calc(8);
  align-items           : flex-start;
  cursor                : pointer;
  line-height           : 1.3;
  $this                 : &;

  &__input {
    position           : absolute;
    appearance         : none;
    -webkit-appearance : none;
    -moz-appearance    : none;

    &:checked {
      & + .check__box .check__icon {
        display         : flex;
        align-self      : center;
        justify-content : center;
      }
    }

    &:disabled + .check__box {
      border-color : var(--gray);
    }

    &:focus-visible + .check__box {
      outline        : 2px solid var(--deep-blue-hover);
      outline-offset : rem-calc(4);
    }
  }

  &__box {
    position      : relative;
    width         : 100%;
    height        : rem-calc(18);
    border        : 1px solid var(--black);
    border-radius : rem-calc(4);
    display       : flex;
  }

  &__icon {
    display : none;
  }

  &--large {
    margin-bottom : rem-calc(15);
    @include breakpoint(large up) {
      font-size     : rem-calc(22);
      margin-bottom : rem-calc(22);
    }

    .check {
      &__box {
        transform : translateY(4px);
      }
    }
  }

  &:hover {
    #{$this}:not(#{$this}--disabled) {
      #{$this} {
        &__box {
          border-color : var(--deep-blue-hover);
        }

        &__icon {
          color : var(--deep-blue-hover);
        }
      }
    }
  }

  &--disabled {
    opacity : .5;
    color   : var(--gray);
    cursor  : not-allowed;
  }
}


.single-check {
  .checkbox-group {
    margin-left : rem-calc(60);
  }
}
</style>
<style lang="scss">
.check {
  &__icon {
    .icon-check-checkbox {
      position  : absolute;
      top       : 50%;
      left      : 50%;
      transform : translate(-50%, -50%);
      width     : rem-calc(14);
      height    : rem-calc(8);
    }
  }

  a {
    color           : var(--pale-blue);
    text-decoration : underline;

    &:hover {
      text-decoration : none;
    }
  }
}
</style>
