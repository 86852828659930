import {
    TextConditionValue,
    CheckConditionValue,
    DateConditionValue,
    IntRangeConditionValue,
    ConditionValue, DateRangeConditionValue, IntConditionValue
} from "@ruscorpora/ruscorpora_api"
import { MAX_INT_32, MAX_TREE_DEPTH_TO_SPLITTING, MIN_INT_32 } from "../constatns";
import md5 from "md5"
import intersection from "lodash/intersection"

export const getDefaultFieldValue = (field, fieldType) => {
    fieldType = fieldType ? fieldType : field.type
    const fieldName = field.name

    if (fieldType === 'int-range') {
        const defaultParams = field?.intRangeOptions?.default || {}
        return ConditionValue.create({
            fieldName: fieldName,
            intRange: IntRangeConditionValue.create({
                begin: defaultParams.hasOwnProperty("begin") ? defaultParams.begin : MIN_INT_32,
                end: defaultParams.hasOwnProperty("end") ? defaultParams.end : MAX_INT_32
            })
        })
    } else if (fieldType === 'date') {
        return ConditionValue.create({
            fieldName: fieldName,
            date: DateConditionValue.create()
        })
    } else if (['int', 'computable-word-select'].includes(fieldType)) {
        return ConditionValue.create({
            fieldName: fieldName,
            int: IntConditionValue.create()
        })
    } else if (fieldType === 'date-range') {
        return ConditionValue.create({
            fieldName: fieldName,
            dateRange: DateRangeConditionValue.create()
        })
    } else if (fieldType === 'check') {
        return ConditionValue.create({
            fieldName: fieldName,
            check: CheckConditionValue.create()
        })
    } else if (fieldType === 'check-group' || fieldType === 'radio') {
        return ConditionValue.create({
            fieldName: fieldName,
            text: TextConditionValue.create({
                v: field.options.filter(x => x.checked).map(x => x.value).join('|')
            })
        })
    } else {
        return ConditionValue.create({
            fieldName: fieldName,
            text: TextConditionValue.create({v: ""})
        })
    }
}

export const configToForm = (schema, ignore_default = false, conditionValues = []) => {
    const results = []
    const filledFields = conditionValues.map(x => {
        if (x.c === 'text') {
            return x.text.v ? x.fieldName : null
        } else {
            return x.fieldName
        }
    }).flat().filter(x => x !== null)

    schema.forEach(card => {
        let skip =
            !card.showByDefault &&
            !ignore_default &&
            !intersection(filledFields, card.fields.map(x => x.name)).length > 0;

        if (skip) return

        const fieldGroup = {cardId: card.cardId}

        card.fields.forEach((field) => {
            if (filledFields.includes(field.name)) {
                fieldGroup[field.name] = conditionValues.find(x => x.fieldName === field.name)
            } else {
                fieldGroup[field.name] = getDefaultFieldValue(field)
            }
        })

        results.push(fieldGroup)
    })

    return results;
}


const getCardConfigByName = (cardId, config) => {
    return config.find(x => x['cardId'] === cardId)
}

export const fillForm = (params, config) => {
    return params.map((card) => {
        const structure = {...getCardConfigByName(card['cardId'], config)};

        structure.fields = [...structure.fields].map((item) => {
            const field = {...item}

            if (card.hasOwnProperty(field.name)) {
                field.value = card[field.name]
            } else {
                field.value = getDefaultFieldValue(field)
            }

            return {...field}
        })

        return structure
    })
}


export const recursiveWalkValues = (node, results = [], cb = () => void (0)) => {
    node.forEach(item => {
        results.push(item.value)
        cb(results, item)

        if (item.children?.length) {
            recursiveWalkValues(item.children, results, cb)
        }
    })

    return results
}

export const formatAttrTree = (tree, level = null, keys = new Map(), parent = null, results = []) => {
    tree.forEach((node, index) => {
        let currentKey = typeof level === 'string' ? md5(`${level}-${index}`) : md5(index);

        if (node.value && !keys.has(node.value)) {
            currentKey = node.value;
            keys.set(currentKey, [currentKey]);
        } else if (node.value && keys.has(node.value)) {
            keys.set(node.value, [...keys.get(node.value), currentKey])
        }

        const newNode = {
            key: currentKey,
            value: currentKey,
            real_value: node.value,
            title: `${node.title}`,
            and_with_group: node.and_with_group || false,
            replace_all_group: node.replace_all_group || false,
            help_text_url: node.help_text_url ?? false,
            combine: node.combine,
            comment: node.comment,
            get related() {
                return keys.get(node.value)
            },
            get parent() {
                if (!parent) return
                return {
                    node: parent
                }
            }
        }

        if (node.hasOwnProperty('children')) {
            newNode.children = formatAttrTree(node.children, newNode.key, keys, newNode)
        }

        results.push(newNode)
    })
    return results
}


/**
 * Обход нижестоящих галочек
 * @param {Object} node
 * @param {Boolean} checked
 * @param {Object} state
 */
export const downCheck = (node, checked, state) => {
    if (node.key) {
        if (checked) {
            if (!state.checked.includes(node.key)) {
                state.checked.push(node.key)
            }
        } else {
            state.checked = state.checked.filter(x => x !== node.key)
        }
    }

    if (node.children) {
        node.children.forEach(item => downCheck(item, checked, state))
    }
}

/**
 * Поиск отмеченных дочерних галочек, возвращает общее кол-во и ко-во отмеченных
 * @param node
 * @param state
 * @param ignore_key
 * @param level for check only first level
 * @param result
 */
export const childrenIsChecked = (node, state, ignore_key = '', level = 0, result = {
    'total': 0, 'checked': 0, 'child_checked': 0
}) => {
    if (ignore_key !== node.key) {
        if (state.checked.includes(node.key)) {
            result.checked++

            if (level === 1) result.child_checked++
        }

        result.total++
    }

    if (node.children) node.children.forEach(item => childrenIsChecked(item, state, ignore_key, level + 1, result))

    return result
}

/**
 * Обход вышестоящих галочек
 * @param {Object} node
 * @param {Boolean} checked
 * @param {Object} state
 */
export const upCheck = (node, checked, state) => {
    const parent = node.parent;
    const parentNode = parent ? parent.node : null;

    if (parentNode) {

        const checkedChildrenCounter = childrenIsChecked(parentNode, state, parentNode.key)

        if (parentNode.replace_all_group) {
            if (checkedChildrenCounter.checked > 0) {
                // Отмечены все суб-галочки
                if (checkedChildrenCounter.total === checkedChildrenCounter.checked) {
                    if (!state.checked.includes(parentNode.key)) {
                        state.checked.push(parentNode.key)
                    }

                    if (!state.halfChecked.includes(parentNode.key)) {
                        state.halfChecked.push(parentNode.key)
                    } else {
                        state.halfChecked = state.halfChecked.filter(x => x !== parentNode.key)
                    }
                } else {
                    if (!state.halfChecked.includes(parentNode.key)) {
                        state.halfChecked.push(parentNode.key)
                    }
                    state.checked = state.checked.filter(x => x !== parentNode.key)
                }
            } else {
                state.checked = state.checked.filter(x => x !== parentNode.key)
                state.halfChecked = state.halfChecked.filter(x => x !== parentNode.key)
            }
        } else {
            /** У родителя есть значение и связи детей к нему разрывается*/
            if (parentNode.real_value) {
                if (checkedChildrenCounter.checked > 0) {
                    if (!state.halfChecked.includes(parentNode.key)) {
                        state.halfChecked.push(parentNode.key)
                    }
                } else {
                    state.halfChecked = state.halfChecked.filter(x => x !== parentNode.key)
                }
            } else {
                if (checkedChildrenCounter.checked > 0) {
                    // Отмечены не все потомки и нужно снять флаг checked с родителя
                    if (checkedChildrenCounter.total !== checkedChildrenCounter.checked) {
                        state.checked = state.checked.filter(x => x !== parentNode.key)

                        if (!state.halfChecked.includes(parentNode.key)) {
                            state.halfChecked.push(parentNode.key)
                        }
                    } else {
                        if (!state.checked.includes(parentNode.key)) {
                            state.checked.push(parentNode.key)
                        }
                        state.halfChecked = state.halfChecked.filter(x => x !== parentNode.key)
                    }
                } else {
                    state.halfChecked = state.halfChecked.filter(x => x !== parentNode.key)
                }
            }
        }
        upCheck(parentNode, checked, state)
    }
}

const findRelatedFromTree = (node, finded = new Set()) => {
    if (node.related && node.related.length > 1) {
        node.related.forEach(x => finded.add(x))
    }

    if (node.children && node.children.length) {
        node.children.forEach(child => findRelatedFromTree(child, finded))
    }

    return finded
}

export const handleTreeCheck = (checkedKeys, event, attrs) => {
    downCheck(event.node.dataRef, event.checked, checkedKeys)
    upCheck(event.node, event.checked, checkedKeys)

    if (attrs) {
        const related = Array.from(findRelatedFromTree(event.node))

        related.filter(x => x !== event.node.value).forEach(x => {
            const node = attrs.find(attr => attr.key === x)
            if (event.checked && !checkedKeys.checked.includes(node.key)) {
                checkedKeys.checked.push(node.key)
            }

            downCheck(node.node, event.checked, checkedKeys)
            upCheck(node.node, event.checked, checkedKeys)
        })
    }
}

export const handleFormulaRuleTypes = (data, attrs, simple = false) => {
    const operators = {
        'COMBINE_AND': ' & ',
        'COMBINE_OR': ' | ',
        'COMBINE_DEFAULT': ' & ',
    }

    let results = ''

    const nodeIsLeaf = (node, checkedList, parent) => {
        if (!parent.children) return false
        let lastLeaf = false;
        let childCounter = 0;
        let childIsNode = false

        while (childCounter < parent.children.length) {
            const currentNode = parent.children[childCounter]

            /*if (!childIsNode) {
                childIsNode = parent.children[childCounter].value === node.value;
                continue;
            }*/

            const {
                child_checked: hasCheckedChildren,
                checked: allChildrenChecked
            } = childrenIsChecked(node, {checked: checkedList}, currentNode.key);

            const nodeIsChecked = checkedList.includes(currentNode.key);

            if (nodeIsChecked || hasCheckedChildren) {
                lastLeaf = childCounter
            }

            childCounter++;
        }

        return Number.isInteger(lastLeaf) && parent.children[lastLeaf].value === node.value
    }
    /**
     * Не влезай убьёт

     * @param node {Object}
     * @param selected {Array | null}
     * @param operator {String | null}
     * @param parent {Object}
     * @return {string}
     */
    const walkOfAttrs = (node, selected = null, operator = null, parent = {}) => {
        node.forEach((nodeItem) => {
                const nodeIsVirtual = nodeItem.real_value === 'undefined' || nodeItem.real_value === undefined;
                const currentSelected = selected || data[nodeItem.key];
                const currentOperator = operator || nodeItem.combine;
                // Условие для упрощенной формулы в подкорпусе
                const isSimple = simple && operator === nodeItem.combine;
                const {
                    child_checked: hasCheckedChildren,
                    checked: allChildrenChecked
                } = childrenIsChecked(nodeItem, {checked: currentSelected}, nodeItem.key);

                let nodeIsChecked = currentSelected.includes(nodeItem.key);

                if (allChildrenChecked && nodeIsChecked && !nodeIsVirtual) {
                    const newNode = {...nodeItem}
                    delete newNode.children
                    nodeItem.children.unshift(newNode)
                    nodeItem.real_value = undefined

                    return walkOfAttrs(
                        nodeItem.children,
                        currentSelected,
                        nodeItem.combine,
                    )
                }

                if (allChildrenChecked || nodeIsChecked) {
                    const isAddAndGroup = nodeItem['and_with_group']

                    /**
                     * Добавление к всей секции родительского ключа. Например: r:concr & gr:s
                     */
                    if (isAddAndGroup) {
                        if (results.length) results += operators[currentOperator]

                        results += `${nodeItem.real_value} & `
                    }

                    if (results.endsWith(')')) results += operators[currentOperator]
                    else if (results.endsWith('END_GROUP')) results += operators[currentOperator]

                    const isLeaf = nodeIsLeaf(nodeItem, currentSelected, nodeItem.parent || parent)

                    if (hasCheckedChildren && !isSimple) results += '('
                    else if (hasCheckedChildren && isSimple) results += 'START_GROUP'

                    if (!isAddAndGroup && !nodeIsVirtual && nodeIsChecked) {
                        results += nodeItem.real_value + (!isLeaf ? operators[currentOperator] : '')
                    }

                    if (nodeItem.children) {
                        walkOfAttrs(
                            nodeItem.children,
                            currentSelected,
                            nodeItem.combine,
                            nodeItem
                        )
                    }

                    if (hasCheckedChildren && !isSimple) results += ')'
                    else if (hasCheckedChildren && isSimple) results += 'END_GROUP'
                }
            }
        )

        return results
    }

    const treeList = attrs.max_depth >= 0 ? attrs.tree : [{
        combine: attrs.combine,
        children: attrs.tree,
        key: attrs.key
    }]

    const selectedValues = attrs.max_depth >= MAX_TREE_DEPTH_TO_SPLITTING ? null : data[attrs.title]

    let result = walkOfAttrs(treeList, selectedValues, attrs.combine)
        .replaceAll(' | )', ')')
        .replaceAll(' & )', ')')
        .replaceAll('END_GROUP', '')
        .replaceAll('START_GROUP', '')

    // TODO: Обработка крайних случаев, когда у группы нет потомков. Требует комплексного решения
    if (result.endsWith(' & ')) {
        result = result.slice(0, -" & ".length)
    }
    if (result.endsWith(' | ')) {
        result = result.slice(0, -" | ".length)
    }

    return result
}

export const extractValuesFromTree = (attrs) => {
    const result = {}
    const root = {}

    if (attrs.max_depth >= MAX_TREE_DEPTH_TO_SPLITTING) {
        attrs.tree.forEach(x => root[x.key] = Array(x.children?.length || 0).fill([]))
    } else {
        root[attrs.title] = Array(attrs.tree.length || 0).fill([])
    }

    if (attrs.max_depth >= MAX_TREE_DEPTH_TO_SPLITTING) {
        Object.keys(root).forEach((key, index) => {
            result[key] = attrs.tree[index].children.map(item => {
                return recursiveWalkValues([item])
            })
        })
    } else {
        result[attrs.title] = root[attrs.title].map((item, index) => {
            return recursiveWalkValues([attrs.tree[index]])
        })
    }


    return result
}
