interface State {
  mainData: {
    headerMenu: Array<any>,
    footerMenu: any,
    context: any,
  },
  API_URL: String
}

export const useWebsiteStore = defineStore('websiteStore', {
  state: (): State => {
    const { locale } = useI18n()

    return {
      mainData: {
        headerMenu: [],
        footerMenu: {},
        context: {
          partners: []
        }
      },
      API_URL: `${useRuntimeConfig().public.django_url}/${locale.value}/api`
    }
  },

  actions: {
    async getMainData() {
      const response: any = await $fetch(`${this.API_URL}/main-data/`);

      this.mainData.headerMenu = response.headerMenu
      this.mainData.footerMenu = response.footerMenu
      this.mainData.context = response.context

      return this.mainData
    },
    startLogin() {
      /* Event-bus */
      return true
    }
  },
  getters: {
    menu(state) {
      return {
        header: state.mainData.headerMenu,
        footer: state.mainData.footerMenu
      }
    },
    context(state) {
      return state.mainData.context
    },
    apiUrl(state) {
      return state.API_URL
    }
  }
})