import type { NuxtApp } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const { $i18n, $message }: { $i18n: any, $message: any } = nuxtApp as NuxtApp;
    const {
        status,
        data
    } = useAuth()

    const $apiFetch = $fetch.create({
        baseURL: `${config.public.django_url?.toString()}/${$i18n.locale.value}`,
        onRequest({ options }) {
            const headers = options?.headers ? new Headers(options.headers) : new Headers();

            if (status.value === "authenticated") {
                headers.set('X-Authorization', `Bearer ${data?.value?.token?.accessToken}`)
            }

            options.headers = headers
        },
        onResponseError({ response }) {
            if (response.status === 429 && response.headers.has('retry-after')) {

                $message.warning({
                    content: $i18n.t('Вы выполняли это действие слишком часто. Попробуйте через {count} {second}', { count: response.headers.get('retry-after'), second: $i18n.t("секунда", parseInt(response.headers.get('retry-after') ?? "0")) })
                })
            }
        }
    })

    return {
        provide: {
            apiFetch: $apiFetch
        }
    }
})