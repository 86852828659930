import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        const { normalizeRouteName } = useRouteName()
        const visibleMenu = useState('visibleMenu', () => false)
        const mobileMenuIsOpen = useState('mobileMenuIsOpen', () => false)
        
        visibleMenu.value = false
        mobileMenuIsOpen.value = false

        if (to.name === from.name && [
            'corpus-id-stats',
            'corpus-id-stats-graph',
            'corpus-id-stats-chrono',
            'subcorpus-id-stats',
            'subcorpus-id-stats-graph',
            'subcorpus-id-stats-chrono',
        ].includes(normalizeRouteName(to.name))) {
            if (savedPosition) {
                return savedPosition
            }
            return null
        }
        
        if (!from.name) return {}
        //if (from.name === to.name) return null

        return {
            top: 0,
            behavior: 'smooth',
        }
    }
}