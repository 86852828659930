import revive_payload_client_4sVQNw7RlN from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/place/jenkins/workspace/deploy-front/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/place/jenkins/workspace/deploy-front/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/place/jenkins/workspace/deploy-front/client/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/place/jenkins/workspace/deploy-front/client/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/place/jenkins/workspace/deploy-front/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/place/jenkins/workspace/deploy-front/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ant_design_5o6Ntgcfpb from "/place/jenkins/workspace/deploy-front/client/plugins/ant-design.ts";
import async_gettext_9e7LeEnAF5 from "/place/jenkins/workspace/deploy-front/client/plugins/async-gettext.js";
import checkbox_IQvNIuTY5Q from "/place/jenkins/workspace/deploy-front/client/plugins/checkbox.ts";
import customFetch_NgGWtsUol9 from "/place/jenkins/workspace/deploy-front/client/plugins/customFetch.ts";
import message_hub_client_o7L9jkn4Vr from "/place/jenkins/workspace/deploy-front/client/plugins/message-hub.client.ts";
import sentry_client_shVUlIjFLk from "/place/jenkins/workspace/deploy-front/client/plugins/sentry.client.ts";
import smart_captcha_client_5TYItXPHX9 from "/place/jenkins/workspace/deploy-front/client/plugins/smart-captcha.client.js";
import vue_final_modal_pML93k5qcp from "/place/jenkins/workspace/deploy-front/client/plugins/vue-final-modal.ts";
import vue_infinite_loading_El1Hn7453q from "/place/jenkins/workspace/deploy-front/client/plugins/vue-infinite-loading.ts";
import vue_select_Hligc0DBp2 from "/place/jenkins/workspace/deploy-front/client/plugins/vue-select.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  plugin_AOuQ1DYzjk,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  ant_design_5o6Ntgcfpb,
  async_gettext_9e7LeEnAF5,
  checkbox_IQvNIuTY5Q,
  customFetch_NgGWtsUol9,
  message_hub_client_o7L9jkn4Vr,
  sentry_client_shVUlIjFLk,
  smart_captcha_client_5TYItXPHX9,
  vue_final_modal_pML93k5qcp,
  vue_infinite_loading_El1Hn7453q,
  vue_select_Hligc0DBp2
]