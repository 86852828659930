import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const timeout = 5000
    // Функция для создания скрытого div для капчи
    function createCaptchaContainer(sitekey, callback, reject) {
        const container = document.createElement('div');
        container.classList.add('smart-captcha');
        container.style.display = 'none'; // Скрыть контейнер до отрисовки капчи
        document.body.appendChild(container);

        // Отрисовка капчи
        const widgetId = window.smartCaptcha.render(container, {
            sitekey,
            invisible: true,
            hideShield: true,
            callback
        });

        // Подписка на событие успешного выполнения капчи для удаления контейнера
        window.smartCaptcha.subscribe(widgetId, 'success', () => {
            window.smartCaptcha.destroy(widgetId)
            // document.body.removeChild(container); // Удаление контейнера после успешного выполнения капчи
        });

        window.smartCaptcha.subscribe(widgetId, 'challenge-hidden', () => {
            window.smartCaptcha.destroy(widgetId);
            reject()
        });

        window.smartCaptcha.subscribe(widgetId, 'network-error', () => {
            window.smartCaptcha.destroy(widgetId);
            reject()
        });

        window.smartCaptcha.execute(widgetId)
    }

    const totalAttempts = 10
    let attempt = 0

    // Обработчик инициализации SmartCaptcha

    const isSmartCaptchaLoaded = setInterval(() => {
        if (++attempt === totalAttempts) {
            /* eslint-disable no-console */
            console.warn(
                `Captcha cannot be initialized for ${timeout}ms. Make sure widget script is loaded`
            )
            /* eslint-enable no-console */

            clearInterval(isSmartCaptchaLoaded)
            return
        }

        if (window.smartCaptcha === undefined) {
            return;
        }

        clearInterval(isSmartCaptchaLoaded)

    }, timeout / totalAttempts)


    const exportHandler = function (skip = false) {
        if (skip) return new Promise((resolve) => setTimeout(() => resolve('test-token'), 1500))

        return new Promise((resolve, reject) => {
            const callback = function (token) {
                resolve(token);
            };

            createCaptchaContainer(config.public.smartcaptcha_public_key, callback, reject);
        });
    }

    return {
        provide: {
            smartcaptcha: exportHandler
        }
    }

})