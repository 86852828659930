import { Date as DatePB } from '@ruscorpora/ruscorpora_api'
// import { getText as $gettext } from "./plugins/localization";
/* TODO переписать gettext */
export const GRAPHIC_FIELD_STATS = 'created'
export const CONTEXT_SORTING = ['right_context', 'left_context', 'form_lcontext', 'form_rcontext']
export const MAX_INT_32 = Math.pow(2, 31) - 1;
export const MIN_INT_32 = (-1 * Math.pow(2, 31));
export const RIGHT_CONTEXT_SORTING = ['right_context', 'form_rcontext']
export const DISABLED_SORT_TO_KWIC_ALL_RESULTS = ['grstd', 'random_doc', 'grauthor', 'grcreated', 'grcreated_inv', 'grbirthday', 'grbirthday_inv']

export const MIN_PB_DATE = DatePB.create({
    day: 1,
    month: 1,
    year: 1
})

export const MAX_PB_DATE = DatePB.create({
    day: 31,
    month: 12,
    year: 2999
})

export const HOMONYMY_STATUSES = {
    'manual': {
        message: 'Омонимия снята',
        icon: 'homonymy-manual'
    },
    'none': {
        message: 'Омонимия не снята',
        icon: 'homonymy-none'
    },
    'auto': {
        message: 'Омонимия снята автоматически',
        icon: 'homonymy-auto'
    },
    'sub_auto': {
        message: 'Есть версия со снятой автоматически омонимией',
        icon: 'homonymy-auto'
    },
    'sub_none': {
        message: 'Есть версия с неснятой омонимией',
        icon: 'homonymy-none'
    },
    'no_tagging': {
        message: 'Без грамматической разметки',
        icon: 'homonymy-no-tagging'
    },
    '': {
        message: 'Документ без разметки',
        icon: 'homonymy-none'
    },
}


export const MAX_TREE_DEPTH_TO_SPLITTING = 4



const BASIC_COLORS = [
    "#8ab7e0",
    "#ffb09f",
    "#b4f5bf",
    "#ef9ca9",
    "#80dfd5",
    "#ffd966",
    "#bda8cd",
    "#a8b2ca",
    "#d8a8c4",
    "#80cfff",
]

export const PALETTE = [
    ...BASIC_COLORS,
    "#c6ced4"
]

export const EXTEND_PALETTE = [
    ...BASIC_COLORS,
    "#6d95bf",
    "#59728c",
    "#456259",
    "#324b26",
    "#1e2e0e",
    "#c49080",
    "#a37060",
    "#825040",
    "#614020",
    "#402000",
    "#c6ced4"
]

export const TRANSLATES = {
    collocate_dist: {
        allValues: 'на расстоянии от <b>{min}</b> до <b>{max}</b> от <b>Ключа</b>',
        onlyBegin: 'на расстоянии от <b>{min}</b> от <b>Ключа</b>',
        onlyEnd: 'на расстоянии до <b>{max}</b> от <b>Ключа</b>',
        beginIsEqualEnd: 'на расстоянии <b>{min}</b> от <b>Ключа</b>',
    },
    dist: {
        allValues: 'на расстоянии от <b>{min}</b> до <b>{max}</b> от <b>Слова {from}</b>',
        onlyBegin: 'на расстоянии от <b>{min}</b> от <b>Слова {from}</b>',
        onlyEnd: 'на расстоянии до <b>{max}</b> от <b>Слова {from}</b>',
        beginIsEqualEnd: 'на расстоянии <b>{min}</b> от <b>Слова {from}</b>',
    },
    INT_RANGE_INCLUDE: 'точное вхождение'
}
