<template>
  <nuxt-icon :name="name" :class="bindClass" class="icon-wrapper" :filled="true" />
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String
    },
    iconStyle: {
      type: String
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    bindClass() {
      return {
        [`icon-${this.name}`]: true,
        [this.extraClass]: !!this.extraClass
      }
    },
    bindAttrs() {
      return {
        [`xlink:href`]: `#i-${this.name}`
      }
    }
  }
}
</script>

<style lang="scss">
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &.nuxt-icon.nuxt-icon--fill svg {
    fill: unset !important;
  }
  svg {
    max-width: 100%;
    max-height: 100%;
    width: inherit;
    height: inherit;
    vertical-align: unset;
    margin-bottom: unset;
  }
}
</style>
