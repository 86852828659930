import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }
  if (sentry.environment === "local") return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }), 
      Sentry.replayIntegration()
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: sentry.traces_sample_rate, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://test3.ruscorpora.ru'],

    replaysSessionSampleRate: sentry.replay_sample_rate,
    replaysOnErrorSampleRate: sentry.error_replay_sample_rate, 
  })
})