import type { MessageApi } from "ant-design-vue/es/message";

export default defineNuxtPlugin((nuxtApp) => {
    const message: any | MessageApi = nuxtApp.$message;
    const lastPushEvents = useState<string[]>('notifications', () => [])

    if (!Array.isArray(lastPushEvents.value)) lastPushEvents.value = []

    nuxtApp.hook('page:finish', () => {
        const notification = useCookie('notification')
        const notyMessage: string | null = notification.value ? notification.value : null

        if (notyMessage && !lastPushEvents.value.includes(notyMessage)) {
            lastPushEvents.value.push(notyMessage)
            message.warning(notyMessage)
            notification.value = ""

            setTimeout(() => {
                lastPushEvents.value = [...lastPushEvents.value].filter(x => x !== notyMessage)
            }, 500)
        }
    })
})