<template>
  <div class="checkbox-group"
       :class="{'checkbox-group-inline': layout !== 'inline', 'checkbox-group-block': layout !== 'inline'}">
    <single-checkbox v-for="(option, index) of arrayOptions" :key="`check_${index}`"
                     :children="option.children"
                     :label="option.title" :value="option.value" v-model="computedValue"
                     @trigger-indeterminate="onTriggerIndeterminateHandler"
    />
  </div>
</template>

<script>
import SingleCheckbox from "./SingleCheckbox";
import { recursiveWalkValues } from "../../../utils/form";

export default {
  name: "Checkbox",
  components: {
    SingleCheckbox,
  },
  emits: ['update:modelValue', 'trigger-indeterminate-child'],
  props: {
    layout: {
      type: String,
      default: 'inline'
    },
    modelValue: {
      type: [Array, String],
      default: () => {
        return []
      }
    },
    options: {
      type: [Array, Object],
      default: () => {
        return []
      }
    },
    parentValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    computedValue: {
      get() {
        if (typeof this.modelValue === 'string') {
          // Hack for value like "A | B"
          // Expected behavior: "A | B|C" => ["A | B", "B"]

          const regExp = new RegExp(/(?! ).\|.(?! )/, 'g')
          const COMBINE_OR = "COMBINE_OR"

          const modelValueWithCombineTokens = this.modelValue.replaceAll(regExp, (match) => {
            return match.replace('|', COMBINE_OR)
          })

          return modelValueWithCombineTokens.split(COMBINE_OR).filter(x => x !== "")
        } else return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val.join('|'))
      }
    },
    arrayOptions() {
      const options = Array.isArray(this.options) ? this.options : [this.options]

      return options.map(option => {
        const newOption = {...option}
        newOption.value = option.value.trim()
        return newOption
      })
    },
  },
  methods: {
    onTriggerIndeterminateHandler() {
      if (!this.parentValue) return

      let checkedChildCount = 0;
      const childOptions = recursiveWalkValues(this.arrayOptions)

      childOptions.forEach(x => {
        if (this.computedValue.includes(x)) {
          checkedChildCount++
        }
      })

      if (checkedChildCount === childOptions.length) {
        this.$emit('update:modelValue', [...new Set([...this.computedValue, this.parentValue])])
      } else {
        this.$emit('update:modelValue', this.computedValue.filter(x => x !== this.parentValue))
      }

      this.$emit('trigger-indeterminate-child')
    }
  }
}
</script>


<style scoped lang="scss">
.checkbox-group {
  gap     : rem-calc(16);
  display : flex;

  &-inline {
    flex-direction : row;
    @include breakpoint(medium down) {
      flex-wrap   : wrap;
    }
  }

  &-block {
    flex-direction : column;
  }
}
</style>

<style lang="scss">
.is-root-checkbox {
  margin-bottom : rem-calc(40);

  & > .single-check {
    & > .check--is-node {
      font-size     : rem-calc(24);
      margin-bottom : rem-calc(24);

      & + .checkbox-group {
        margin-left : 0;
      }
    }
  }
}

.checkbox-group-block {
  & > .single-check:first-child {
    margin-top : rem-calc(16);
  }

  & > .single-check:last-child {
    margin-top : 0;
  }
}
</style>
