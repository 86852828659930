<template>
    <div class="error-page">
        <div class="error-page__layout">
            <NuxtLinkLocale class="logo logo--mobile" to="/">
                <img :src="locale === 'ru' ? '/images/logo-sm.svg' : '/images/logo-sm-en.svg'" aria-hidden="true">
            </NuxtLinkLocale>

            <template v-if="props.error?.statusCode === 404">
                <div class="error-page__body">
                    <div>
                        <NuxtLinkLocale class="logo logo--desktop" to="/">
                            <img :src="locale === 'ru' ? '/images/logo.svg' : '/images/logo-en.svg'" aria-hidden="true">
                        </NuxtLinkLocale>
                        <div class="error-page__text-node">
                            <img src="/images/errors/404.svg" class="error-page__picture" aria-hidden="true"
                                style="aspect-ratio: 500/369;">
                            <h1 v-html="pageTitle" />
                        </div>
                    </div>

                    <p class="bg__error-text error-page__footnote">
                        <NuxtLinkLocale to="/" class="link link--no-under link--deep-blue">
                            ← {{ t('перейти на главную') }}
                        </NuxtLinkLocale>
                    </p>
                </div>
            </template>
            <template v-else>
                <div class="error-page__body">
                    <div>
                        <NuxtLinkLocale class="logo logo--desktop" to="/">
                            <img :src="locale === 'ru' ? '/images/logo.svg' : '/images/logo-en.svg'" aria-hidden="true">
                        </NuxtLinkLocale>
                        <div class="error-page__text-node">
                            <img src="/images/errors/500.svg" class="error-page__picture" aria-hidden="true"
                                style="aspect-ratio: 401/400;">
                            <h1 v-html="pageTitle" />
                            <p v-html="t('Мы уже работаем<br>над устранением проблемы')" />
                        </div>
                    </div>

                    <p class="bg__error-text error-page__footnote">
                        ERROR <span>#{{ error?.statusCode ?? 502 }}</span>
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const { locale, t } = useI18n();

defineOptions({
    layout: 'error'
})

const props = defineProps({
    error: Object as () => NuxtError
})

const pageTitle = computed(() => {
    return props.error?.statusCode === 404 ? t('Страница<br>не найдена') : t('Сервис временно<br>недоступен')
})

useSeoMeta({
    title: pageTitle.value.replace('<br>', ' ')
})
</script>

<style lang="scss" scoped>
html,
body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.logo {
    position: relative;
    margin-bottom: 40px;
    max-width: 114px;
    max-height: 57px;


    &--desktop {
        display: none;

        @include breakpoint(small up) {
            display: block;
        }
    }

    &--mobile {
        display: block;

        img {
            width: 108px;
        }

        @include breakpoint(small up) {
            display: none;
        }
    }

    @include breakpoint(small up) {
        margin-bottom: 80px;
        justify-content: center;
    }
}

.error-page {
    min-width: 100dvw;

    &__layout {
        width: 100%;
        max-width: 700px;
        padding: 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        min-height: 100dvh;

        @include breakpoint(small up) {
            justify-content: center;

        }

        @include breakpoint(medium up) {
            padding: 0;
        }
    }

    &__text-node {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 11px;
        @include breakpoint(small up) {
            gap: 30px;
        }
    }

    &__body {
        flex-grow: 1;
        display: grid;
        align-items: flex-end;
        position: relative;

        @include breakpoint(small up) {
            flex-grow: 0;
        }
    }

    &__picture {
        position: absolute;
        max-width: 100%;
        z-index: -1;
        transform: translateY(-50%);
        right: 0;
        top: 50%;
    }

    &__footnote {
        align-self: flex-end;
        margin-top: 20px;

        @include breakpoint(small up) {
            align-self: initial;
            margin-top: 30px;
        }
    }

    .bg {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 35px;
        min-height: 100dvh;
    }

    .container {
        padding: 0 0;
    }

    .bg__error-pic {
        position: absolute;
        left: 0;
        max-width: 100%;
        max-height: 300px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    @media all and (min-width : 768px) {
        .bg {
            justify-content: center;
            align-items: center;
        }

    }


    h1 {
        position: relative;
        font-weight: 700;
        font-size: 28px;
        line-height: 1.1;
        color: #1C1C1C;
        text-transform: uppercase;
        font-family: "Noto Sans", Tahoma, Arial, sans-serif;
    }

    @media all and (min-width : 576px) {
        h1 {
            font-size: 35px;
        }

    }

    @media all and (min-width : 726px) {
        h1 {
            font-size: 48px;
        }

    }

    p {
        position: relative;
        font-family: 'Charis SIL', Tahoma, Arial, sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        color: #3F3F46;
        line-height: 1.1;
    }

    .bg__text {
        position: relative;
        //margin: auto;
        flex-grow: 1;
        max-width: 700px;
        width: 100%;
    }

    @media all and (min-width : 768px) {
        .bg {
            &__text {
                flex-grow: 0;
            }
        }


    }

    .bg__error-text {
        position: relative;
        font-family: "Noto Sans", Tahoma, Arial, sans-serif;
        color: #2D3B60;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
    }

    @media all and (min-width : 768px) {
        .bg__error-text {}

    }

    span {
        font-weight: 700;
    }
}
</style>