import { default as after_45success_45loginOfBoRMN6IwMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-login.vue?macro=true";
import { default as after_45success_45singupU41Odol05qMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-singup.vue?macro=true";
import { default as _91id_93iU2daPo5q8Meta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/[id].vue?macro=true";
import { default as fail06KE4YCpldMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/fail.vue?macro=true";
import { default as successa0nilw2FuiMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/success.vue?macro=true";
import { default as comparesXM8DYGJ2l5Meta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/compares.vue?macro=true";
import { default as editAwFIuhZ5NNMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/edit.vue?macro=true";
import { default as exportsqydIpTtuSiMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/exports.vue?macro=true";
import { default as homecnG6I47x4sMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/home.vue?macro=true";
import { default as queries4cuyWoOQr8Meta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/queries.vue?macro=true";
import { default as settingscfoMp8WpHaMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/settings.vue?macro=true";
import { default as profileiBavAIjNCyMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/profile.vue?macro=true";
import { default as _91id_93augSQHFOStMeta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/reset-password/[id].vue?macro=true";
import { default as token_45failGevoBUKHU6Meta } from "/place/jenkins/workspace/deploy-front/client/pages/accounts/token-fail.vue?macro=true";
import { default as freqPlwQoFLYYeMeta } from "/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/chart/freq.vue?macro=true";
import { default as index8HFfuBtqBYMeta } from "/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/index.vue?macro=true";
import { default as _91id_93dNjNgU6JIQMeta } from "/place/jenkins/workspace/deploy-front/client/pages/compare/[id].vue?macro=true";
import { default as _91slug_93DPDFzYeSZ9Meta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/data/[slug].vue?macro=true";
import { default as frequency_45dictionaryCrgdT5a4xHMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/frequency-dictionary.vue?macro=true";
import { default as indexMgswR939Y8Meta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/index.vue?macro=true";
import { default as publicationsPuL64dSP3TMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/publications.vue?macro=true";
import { default as chronoFQ8TY9GZnkMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/chrono.vue?macro=true";
import { default as indexssT2bA52xkMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/index.vue?macro=true";
import { default as stats11h47ICwmxMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats.vue?macro=true";
import { default as _91id_936vUhkzah0IMeta } from "/place/jenkins/workspace/deploy-front/client/pages/corpus/[id].vue?macro=true";
import { default as exerciserGDyab9CvQMeta } from "/place/jenkins/workspace/deploy-front/client/pages/exercise.vue?macro=true";
import { default as exploreJyfcH5QSa5Meta } from "/place/jenkins/workspace/deploy-front/client/pages/explore.vue?macro=true";
import { default as full_45textjmAgi5RcW4Meta } from "/place/jenkins/workspace/deploy-front/client/pages/full-text.vue?macro=true";
import { default as index08NGtYSEwTMeta } from "/place/jenkins/workspace/deploy-front/client/pages/index.vue?macro=true";
import { default as _91id_93XXMTMrPGnAMeta } from "/place/jenkins/workspace/deploy-front/client/pages/license-content/[id].vue?macro=true";
import { default as _91id_93OvNbj4k85QMeta } from "/place/jenkins/workspace/deploy-front/client/pages/news/[id].vue?macro=true";
import { default as indexwBgwkq0TMuMeta } from "/place/jenkins/workspace/deploy-front/client/pages/news/index.vue?macro=true";
import { default as _91id_93ZDB5obFmmBMeta } from "/place/jenkins/workspace/deploy-front/client/pages/page/[id].vue?macro=true";
import { default as corpora_45publicdap38ACCuyMeta } from "/place/jenkins/workspace/deploy-front/client/pages/page/corpora-public.vue?macro=true";
import { default as preview_45_91id_93Boz6gFDyGqMeta } from "/place/jenkins/workspace/deploy-front/client/pages/page/preview-[id].vue?macro=true";
import { default as resultsEuMIblWEqwMeta } from "/place/jenkins/workspace/deploy-front/client/pages/results.vue?macro=true";
import { default as _91id_93DPOkalOwPDMeta } from "/place/jenkins/workspace/deploy-front/client/pages/search/[id].vue?macro=true";
import { default as indexM4FSKfoqdYMeta } from "/place/jenkins/workspace/deploy-front/client/pages/search/index.vue?macro=true";
import { default as site_45search2rMd202lHSMeta } from "/place/jenkins/workspace/deploy-front/client/pages/site-search.vue?macro=true";
import { default as statsIymmAFjWijMeta } from "/place/jenkins/workspace/deploy-front/client/pages/stats.vue?macro=true";
import { default as docsIubLyGQ9HcMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/docs.vue?macro=true";
import { default as frequency_45dictionaryLb02dN50rGMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/frequency-dictionary.vue?macro=true";
import { default as indexf6sO8bRbSnMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/index.vue?macro=true";
import { default as chronoJnQuRiFhmeMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/chrono.vue?macro=true";
import { default as indexJAmRfUbALMMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/index.vue?macro=true";
import { default as stats18zS2LD21QMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats.vue?macro=true";
import { default as _91id_93wJreN3LkcfMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id].vue?macro=true";
import { default as indexTxpVuXwceWMeta } from "/place/jenkins/workspace/deploy-front/client/pages/subcorpus/index.vue?macro=true";
import { default as _91id_93fq5e2Ck2AMMeta } from "/place/jenkins/workspace/deploy-front/client/pages/word/[id].vue?macro=true";
export default [
  {
    name: after_45success_45loginOfBoRMN6IwMeta?.name ?? "accounts-after-success-login___ru",
    path: after_45success_45loginOfBoRMN6IwMeta?.path ?? "/accounts/after-success-login",
    meta: after_45success_45loginOfBoRMN6IwMeta || {},
    alias: after_45success_45loginOfBoRMN6IwMeta?.alias || [],
    redirect: after_45success_45loginOfBoRMN6IwMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-login.vue").then(m => m.default || m)
  },
  {
    name: after_45success_45loginOfBoRMN6IwMeta?.name ?? "accounts-after-success-login___en",
    path: after_45success_45loginOfBoRMN6IwMeta?.path ?? "/en/accounts/after-success-login",
    meta: after_45success_45loginOfBoRMN6IwMeta || {},
    alias: after_45success_45loginOfBoRMN6IwMeta?.alias || [],
    redirect: after_45success_45loginOfBoRMN6IwMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-login.vue").then(m => m.default || m)
  },
  {
    name: after_45success_45singupU41Odol05qMeta?.name ?? "accounts-after-success-singup___ru",
    path: after_45success_45singupU41Odol05qMeta?.path ?? "/accounts/after-success-singup",
    meta: after_45success_45singupU41Odol05qMeta || {},
    alias: after_45success_45singupU41Odol05qMeta?.alias || [],
    redirect: after_45success_45singupU41Odol05qMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-singup.vue").then(m => m.default || m)
  },
  {
    name: after_45success_45singupU41Odol05qMeta?.name ?? "accounts-after-success-singup___en",
    path: after_45success_45singupU41Odol05qMeta?.path ?? "/en/accounts/after-success-singup",
    meta: after_45success_45singupU41Odol05qMeta || {},
    alias: after_45success_45singupU41Odol05qMeta?.alias || [],
    redirect: after_45success_45singupU41Odol05qMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/after-success-singup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iU2daPo5q8Meta?.name ?? "accounts-confirm-email-id___ru",
    path: _91id_93iU2daPo5q8Meta?.path ?? "/accounts/confirm-email/:id()",
    meta: _91id_93iU2daPo5q8Meta || {},
    alias: _91id_93iU2daPo5q8Meta?.alias || [],
    redirect: _91id_93iU2daPo5q8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93iU2daPo5q8Meta?.name ?? "accounts-confirm-email-id___en",
    path: _91id_93iU2daPo5q8Meta?.path ?? "/en/accounts/confirm-email/:id()",
    meta: _91id_93iU2daPo5q8Meta || {},
    alias: _91id_93iU2daPo5q8Meta?.alias || [],
    redirect: _91id_93iU2daPo5q8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/[id].vue").then(m => m.default || m)
  },
  {
    name: fail06KE4YCpldMeta?.name ?? "accounts-confirm-email-fail___ru",
    path: fail06KE4YCpldMeta?.path ?? "/accounts/confirm-email/fail",
    meta: fail06KE4YCpldMeta || {},
    alias: fail06KE4YCpldMeta?.alias || [],
    redirect: fail06KE4YCpldMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/fail.vue").then(m => m.default || m)
  },
  {
    name: fail06KE4YCpldMeta?.name ?? "accounts-confirm-email-fail___en",
    path: fail06KE4YCpldMeta?.path ?? "/en/accounts/confirm-email/fail",
    meta: fail06KE4YCpldMeta || {},
    alias: fail06KE4YCpldMeta?.alias || [],
    redirect: fail06KE4YCpldMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/fail.vue").then(m => m.default || m)
  },
  {
    name: successa0nilw2FuiMeta?.name ?? "accounts-confirm-email-success___ru",
    path: successa0nilw2FuiMeta?.path ?? "/accounts/confirm-email/success",
    meta: successa0nilw2FuiMeta || {},
    alias: successa0nilw2FuiMeta?.alias || [],
    redirect: successa0nilw2FuiMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/success.vue").then(m => m.default || m)
  },
  {
    name: successa0nilw2FuiMeta?.name ?? "accounts-confirm-email-success___en",
    path: successa0nilw2FuiMeta?.path ?? "/en/accounts/confirm-email/success",
    meta: successa0nilw2FuiMeta || {},
    alias: successa0nilw2FuiMeta?.alias || [],
    redirect: successa0nilw2FuiMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/confirm-email/success.vue").then(m => m.default || m)
  },
  {
    name: profileiBavAIjNCyMeta?.name ?? "accounts-profile___ru",
    path: profileiBavAIjNCyMeta?.path ?? "/accounts/profile",
    meta: profileiBavAIjNCyMeta || {},
    alias: profileiBavAIjNCyMeta?.alias || [],
    redirect: profileiBavAIjNCyMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile.vue").then(m => m.default || m),
    children: [
  {
    name: comparesXM8DYGJ2l5Meta?.name ?? "accounts-profile-compares___ru",
    path: comparesXM8DYGJ2l5Meta?.path ?? "compares",
    meta: comparesXM8DYGJ2l5Meta || {},
    alias: comparesXM8DYGJ2l5Meta?.alias || [],
    redirect: comparesXM8DYGJ2l5Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/compares.vue").then(m => m.default || m)
  },
  {
    name: editAwFIuhZ5NNMeta?.name ?? "accounts-profile-edit___ru",
    path: editAwFIuhZ5NNMeta?.path ?? "edit",
    meta: editAwFIuhZ5NNMeta || {},
    alias: editAwFIuhZ5NNMeta?.alias || [],
    redirect: editAwFIuhZ5NNMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: exportsqydIpTtuSiMeta?.name ?? "accounts-profile-exports___ru",
    path: exportsqydIpTtuSiMeta?.path ?? "exports",
    meta: exportsqydIpTtuSiMeta || {},
    alias: exportsqydIpTtuSiMeta?.alias || [],
    redirect: exportsqydIpTtuSiMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/exports.vue").then(m => m.default || m)
  },
  {
    name: homecnG6I47x4sMeta?.name ?? "accounts-profile-home___ru",
    path: homecnG6I47x4sMeta?.path ?? "home",
    meta: homecnG6I47x4sMeta || {},
    alias: homecnG6I47x4sMeta?.alias || [],
    redirect: homecnG6I47x4sMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/home.vue").then(m => m.default || m)
  },
  {
    name: queries4cuyWoOQr8Meta?.name ?? "accounts-profile-queries___ru",
    path: queries4cuyWoOQr8Meta?.path ?? "queries",
    meta: queries4cuyWoOQr8Meta || {},
    alias: queries4cuyWoOQr8Meta?.alias || [],
    redirect: queries4cuyWoOQr8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/queries.vue").then(m => m.default || m)
  },
  {
    name: settingscfoMp8WpHaMeta?.name ?? "accounts-profile-settings___ru",
    path: settingscfoMp8WpHaMeta?.path ?? "settings",
    meta: settingscfoMp8WpHaMeta || {},
    alias: settingscfoMp8WpHaMeta?.alias || [],
    redirect: settingscfoMp8WpHaMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileiBavAIjNCyMeta?.name ?? "accounts-profile___en",
    path: profileiBavAIjNCyMeta?.path ?? "/en/accounts/profile",
    meta: profileiBavAIjNCyMeta || {},
    alias: profileiBavAIjNCyMeta?.alias || [],
    redirect: profileiBavAIjNCyMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile.vue").then(m => m.default || m),
    children: [
  {
    name: comparesXM8DYGJ2l5Meta?.name ?? "accounts-profile-compares___en",
    path: comparesXM8DYGJ2l5Meta?.path ?? "compares",
    meta: comparesXM8DYGJ2l5Meta || {},
    alias: comparesXM8DYGJ2l5Meta?.alias || [],
    redirect: comparesXM8DYGJ2l5Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/compares.vue").then(m => m.default || m)
  },
  {
    name: editAwFIuhZ5NNMeta?.name ?? "accounts-profile-edit___en",
    path: editAwFIuhZ5NNMeta?.path ?? "edit",
    meta: editAwFIuhZ5NNMeta || {},
    alias: editAwFIuhZ5NNMeta?.alias || [],
    redirect: editAwFIuhZ5NNMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: exportsqydIpTtuSiMeta?.name ?? "accounts-profile-exports___en",
    path: exportsqydIpTtuSiMeta?.path ?? "exports",
    meta: exportsqydIpTtuSiMeta || {},
    alias: exportsqydIpTtuSiMeta?.alias || [],
    redirect: exportsqydIpTtuSiMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/exports.vue").then(m => m.default || m)
  },
  {
    name: homecnG6I47x4sMeta?.name ?? "accounts-profile-home___en",
    path: homecnG6I47x4sMeta?.path ?? "home",
    meta: homecnG6I47x4sMeta || {},
    alias: homecnG6I47x4sMeta?.alias || [],
    redirect: homecnG6I47x4sMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/home.vue").then(m => m.default || m)
  },
  {
    name: queries4cuyWoOQr8Meta?.name ?? "accounts-profile-queries___en",
    path: queries4cuyWoOQr8Meta?.path ?? "queries",
    meta: queries4cuyWoOQr8Meta || {},
    alias: queries4cuyWoOQr8Meta?.alias || [],
    redirect: queries4cuyWoOQr8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/queries.vue").then(m => m.default || m)
  },
  {
    name: settingscfoMp8WpHaMeta?.name ?? "accounts-profile-settings___en",
    path: settingscfoMp8WpHaMeta?.path ?? "settings",
    meta: settingscfoMp8WpHaMeta || {},
    alias: settingscfoMp8WpHaMeta?.alias || [],
    redirect: settingscfoMp8WpHaMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/profile/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93augSQHFOStMeta?.name ?? "accounts-reset-password-id___ru",
    path: _91id_93augSQHFOStMeta?.path ?? "/accounts/reset-password/:id()",
    meta: _91id_93augSQHFOStMeta || {},
    alias: _91id_93augSQHFOStMeta?.alias || [],
    redirect: _91id_93augSQHFOStMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93augSQHFOStMeta?.name ?? "accounts-reset-password-id___en",
    path: _91id_93augSQHFOStMeta?.path ?? "/en/accounts/reset-password/:id()",
    meta: _91id_93augSQHFOStMeta || {},
    alias: _91id_93augSQHFOStMeta?.alias || [],
    redirect: _91id_93augSQHFOStMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: token_45failGevoBUKHU6Meta?.name ?? "accounts-token-fail___ru",
    path: token_45failGevoBUKHU6Meta?.path ?? "/accounts/token-fail",
    meta: token_45failGevoBUKHU6Meta || {},
    alias: token_45failGevoBUKHU6Meta?.alias || [],
    redirect: token_45failGevoBUKHU6Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/token-fail.vue").then(m => m.default || m)
  },
  {
    name: token_45failGevoBUKHU6Meta?.name ?? "accounts-token-fail___en",
    path: token_45failGevoBUKHU6Meta?.path ?? "/en/accounts/token-fail",
    meta: token_45failGevoBUKHU6Meta || {},
    alias: token_45failGevoBUKHU6Meta?.alias || [],
    redirect: token_45failGevoBUKHU6Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/accounts/token-fail.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dNjNgU6JIQMeta?.name ?? undefined,
    path: _91id_93dNjNgU6JIQMeta?.path ?? "/compare/:id()",
    meta: _91id_93dNjNgU6JIQMeta || {},
    alias: _91id_93dNjNgU6JIQMeta?.alias || [],
    redirect: _91id_93dNjNgU6JIQMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id].vue").then(m => m.default || m),
    children: [
  {
    name: freqPlwQoFLYYeMeta?.name ?? "compare-id-chart-freq___ru",
    path: freqPlwQoFLYYeMeta?.path ?? "chart/freq",
    meta: freqPlwQoFLYYeMeta || {},
    alias: freqPlwQoFLYYeMeta?.alias || [],
    redirect: freqPlwQoFLYYeMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/chart/freq.vue").then(m => m.default || m)
  },
  {
    name: index8HFfuBtqBYMeta?.name ?? "compare-id___ru",
    path: index8HFfuBtqBYMeta?.path ?? "",
    meta: index8HFfuBtqBYMeta || {},
    alias: index8HFfuBtqBYMeta?.alias || [],
    redirect: index8HFfuBtqBYMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93dNjNgU6JIQMeta?.name ?? undefined,
    path: _91id_93dNjNgU6JIQMeta?.path ?? "/en/compare/:id()",
    meta: _91id_93dNjNgU6JIQMeta || {},
    alias: _91id_93dNjNgU6JIQMeta?.alias || [],
    redirect: _91id_93dNjNgU6JIQMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id].vue").then(m => m.default || m),
    children: [
  {
    name: freqPlwQoFLYYeMeta?.name ?? "compare-id-chart-freq___en",
    path: freqPlwQoFLYYeMeta?.path ?? "chart/freq",
    meta: freqPlwQoFLYYeMeta || {},
    alias: freqPlwQoFLYYeMeta?.alias || [],
    redirect: freqPlwQoFLYYeMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/chart/freq.vue").then(m => m.default || m)
  },
  {
    name: index8HFfuBtqBYMeta?.name ?? "compare-id___en",
    path: index8HFfuBtqBYMeta?.path ?? "",
    meta: index8HFfuBtqBYMeta || {},
    alias: index8HFfuBtqBYMeta?.alias || [],
    redirect: index8HFfuBtqBYMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/compare/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_936vUhkzah0IMeta?.name ?? undefined,
    path: _91id_936vUhkzah0IMeta?.path ?? "/corpus/:id()",
    meta: _91id_936vUhkzah0IMeta || {},
    alias: _91id_936vUhkzah0IMeta?.alias || [],
    redirect: _91id_936vUhkzah0IMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93DPDFzYeSZ9Meta?.name ?? "corpus-id-data-slug___ru",
    path: _91slug_93DPDFzYeSZ9Meta?.path ?? "data/:slug()",
    meta: _91slug_93DPDFzYeSZ9Meta || {},
    alias: _91slug_93DPDFzYeSZ9Meta?.alias || [],
    redirect: _91slug_93DPDFzYeSZ9Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/data/[slug].vue").then(m => m.default || m)
  },
  {
    name: frequency_45dictionaryCrgdT5a4xHMeta?.name ?? "corpus-id-frequency-dictionary___ru",
    path: frequency_45dictionaryCrgdT5a4xHMeta?.path ?? "frequency-dictionary",
    meta: frequency_45dictionaryCrgdT5a4xHMeta || {},
    alias: frequency_45dictionaryCrgdT5a4xHMeta?.alias || [],
    redirect: frequency_45dictionaryCrgdT5a4xHMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/frequency-dictionary.vue").then(m => m.default || m)
  },
  {
    name: indexMgswR939Y8Meta?.name ?? "corpus-id___ru",
    path: indexMgswR939Y8Meta?.path ?? "",
    meta: indexMgswR939Y8Meta || {},
    alias: indexMgswR939Y8Meta?.alias || [],
    redirect: indexMgswR939Y8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: publicationsPuL64dSP3TMeta?.name ?? "corpus-id-publications___ru",
    path: publicationsPuL64dSP3TMeta?.path ?? "publications",
    meta: publicationsPuL64dSP3TMeta || {},
    alias: publicationsPuL64dSP3TMeta?.alias || [],
    redirect: publicationsPuL64dSP3TMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/publications.vue").then(m => m.default || m)
  },
  {
    name: stats11h47ICwmxMeta?.name ?? undefined,
    path: stats11h47ICwmxMeta?.path ?? "stats",
    meta: stats11h47ICwmxMeta || {},
    alias: stats11h47ICwmxMeta?.alias || [],
    redirect: stats11h47ICwmxMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats.vue").then(m => m.default || m),
    children: [
  {
    name: chronoFQ8TY9GZnkMeta?.name ?? "corpus-id-stats-chrono___ru",
    path: chronoFQ8TY9GZnkMeta?.path ?? "chrono",
    meta: chronoFQ8TY9GZnkMeta || {},
    alias: chronoFQ8TY9GZnkMeta?.alias || [],
    redirect: chronoFQ8TY9GZnkMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/chrono.vue").then(m => m.default || m)
  },
  {
    name: indexssT2bA52xkMeta?.name ?? "corpus-id-stats___ru",
    path: indexssT2bA52xkMeta?.path ?? "",
    meta: indexssT2bA52xkMeta || {},
    alias: indexssT2bA52xkMeta?.alias || [],
    redirect: indexssT2bA52xkMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91id_936vUhkzah0IMeta?.name ?? undefined,
    path: _91id_936vUhkzah0IMeta?.path ?? "/en/corpus/:id()",
    meta: _91id_936vUhkzah0IMeta || {},
    alias: _91id_936vUhkzah0IMeta?.alias || [],
    redirect: _91id_936vUhkzah0IMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93DPDFzYeSZ9Meta?.name ?? "corpus-id-data-slug___en",
    path: _91slug_93DPDFzYeSZ9Meta?.path ?? "data/:slug()",
    meta: _91slug_93DPDFzYeSZ9Meta || {},
    alias: _91slug_93DPDFzYeSZ9Meta?.alias || [],
    redirect: _91slug_93DPDFzYeSZ9Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/data/[slug].vue").then(m => m.default || m)
  },
  {
    name: frequency_45dictionaryCrgdT5a4xHMeta?.name ?? "corpus-id-frequency-dictionary___en",
    path: frequency_45dictionaryCrgdT5a4xHMeta?.path ?? "frequency-dictionary",
    meta: frequency_45dictionaryCrgdT5a4xHMeta || {},
    alias: frequency_45dictionaryCrgdT5a4xHMeta?.alias || [],
    redirect: frequency_45dictionaryCrgdT5a4xHMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/frequency-dictionary.vue").then(m => m.default || m)
  },
  {
    name: indexMgswR939Y8Meta?.name ?? "corpus-id___en",
    path: indexMgswR939Y8Meta?.path ?? "",
    meta: indexMgswR939Y8Meta || {},
    alias: indexMgswR939Y8Meta?.alias || [],
    redirect: indexMgswR939Y8Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: publicationsPuL64dSP3TMeta?.name ?? "corpus-id-publications___en",
    path: publicationsPuL64dSP3TMeta?.path ?? "publications",
    meta: publicationsPuL64dSP3TMeta || {},
    alias: publicationsPuL64dSP3TMeta?.alias || [],
    redirect: publicationsPuL64dSP3TMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/publications.vue").then(m => m.default || m)
  },
  {
    name: stats11h47ICwmxMeta?.name ?? undefined,
    path: stats11h47ICwmxMeta?.path ?? "stats",
    meta: stats11h47ICwmxMeta || {},
    alias: stats11h47ICwmxMeta?.alias || [],
    redirect: stats11h47ICwmxMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats.vue").then(m => m.default || m),
    children: [
  {
    name: chronoFQ8TY9GZnkMeta?.name ?? "corpus-id-stats-chrono___en",
    path: chronoFQ8TY9GZnkMeta?.path ?? "chrono",
    meta: chronoFQ8TY9GZnkMeta || {},
    alias: chronoFQ8TY9GZnkMeta?.alias || [],
    redirect: chronoFQ8TY9GZnkMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/chrono.vue").then(m => m.default || m)
  },
  {
    name: indexssT2bA52xkMeta?.name ?? "corpus-id-stats___en",
    path: indexssT2bA52xkMeta?.path ?? "",
    meta: indexssT2bA52xkMeta || {},
    alias: indexssT2bA52xkMeta?.alias || [],
    redirect: indexssT2bA52xkMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/corpus/[id]/stats/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: exerciserGDyab9CvQMeta?.name ?? "exercise___ru",
    path: exerciserGDyab9CvQMeta?.path ?? "/exercise",
    meta: exerciserGDyab9CvQMeta || {},
    alias: exerciserGDyab9CvQMeta?.alias || [],
    redirect: exerciserGDyab9CvQMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/exercise.vue").then(m => m.default || m)
  },
  {
    name: exerciserGDyab9CvQMeta?.name ?? "exercise___en",
    path: exerciserGDyab9CvQMeta?.path ?? "/en/exercise",
    meta: exerciserGDyab9CvQMeta || {},
    alias: exerciserGDyab9CvQMeta?.alias || [],
    redirect: exerciserGDyab9CvQMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/exercise.vue").then(m => m.default || m)
  },
  {
    name: exploreJyfcH5QSa5Meta?.name ?? "explore___ru",
    path: exploreJyfcH5QSa5Meta?.path ?? "/explore",
    meta: exploreJyfcH5QSa5Meta || {},
    alias: exploreJyfcH5QSa5Meta?.alias || [],
    redirect: exploreJyfcH5QSa5Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: exploreJyfcH5QSa5Meta?.name ?? "explore___en",
    path: exploreJyfcH5QSa5Meta?.path ?? "/en/explore",
    meta: exploreJyfcH5QSa5Meta || {},
    alias: exploreJyfcH5QSa5Meta?.alias || [],
    redirect: exploreJyfcH5QSa5Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: full_45textjmAgi5RcW4Meta?.name ?? "full-text___ru",
    path: full_45textjmAgi5RcW4Meta?.path ?? "/full-text",
    meta: full_45textjmAgi5RcW4Meta || {},
    alias: full_45textjmAgi5RcW4Meta?.alias || [],
    redirect: full_45textjmAgi5RcW4Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/full-text.vue").then(m => m.default || m)
  },
  {
    name: full_45textjmAgi5RcW4Meta?.name ?? "full-text___en",
    path: full_45textjmAgi5RcW4Meta?.path ?? "/en/full-text",
    meta: full_45textjmAgi5RcW4Meta || {},
    alias: full_45textjmAgi5RcW4Meta?.alias || [],
    redirect: full_45textjmAgi5RcW4Meta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/full-text.vue").then(m => m.default || m)
  },
  {
    name: index08NGtYSEwTMeta?.name ?? "index___ru",
    path: index08NGtYSEwTMeta?.path ?? "/",
    meta: index08NGtYSEwTMeta || {},
    alias: index08NGtYSEwTMeta?.alias || [],
    redirect: index08NGtYSEwTMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index08NGtYSEwTMeta?.name ?? "index___en",
    path: index08NGtYSEwTMeta?.path ?? "/en",
    meta: index08NGtYSEwTMeta || {},
    alias: index08NGtYSEwTMeta?.alias || [],
    redirect: index08NGtYSEwTMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XXMTMrPGnAMeta?.name ?? "license-content-id___ru",
    path: _91id_93XXMTMrPGnAMeta?.path ?? "/license-content/:id()",
    meta: _91id_93XXMTMrPGnAMeta || {},
    alias: _91id_93XXMTMrPGnAMeta?.alias || [],
    redirect: _91id_93XXMTMrPGnAMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/license-content/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XXMTMrPGnAMeta?.name ?? "license-content-id___en",
    path: _91id_93XXMTMrPGnAMeta?.path ?? "/en/license-content/:id()",
    meta: _91id_93XXMTMrPGnAMeta || {},
    alias: _91id_93XXMTMrPGnAMeta?.alias || [],
    redirect: _91id_93XXMTMrPGnAMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/license-content/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OvNbj4k85QMeta?.name ?? "news-id___ru",
    path: _91id_93OvNbj4k85QMeta?.path ?? "/news/:id()",
    meta: _91id_93OvNbj4k85QMeta || {},
    alias: _91id_93OvNbj4k85QMeta?.alias || [],
    redirect: _91id_93OvNbj4k85QMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OvNbj4k85QMeta?.name ?? "news-id___en",
    path: _91id_93OvNbj4k85QMeta?.path ?? "/en/news/:id()",
    meta: _91id_93OvNbj4k85QMeta || {},
    alias: _91id_93OvNbj4k85QMeta?.alias || [],
    redirect: _91id_93OvNbj4k85QMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwBgwkq0TMuMeta?.name ?? "news___ru",
    path: indexwBgwkq0TMuMeta?.path ?? "/news",
    meta: indexwBgwkq0TMuMeta || {},
    alias: indexwBgwkq0TMuMeta?.alias || [],
    redirect: indexwBgwkq0TMuMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexwBgwkq0TMuMeta?.name ?? "news___en",
    path: indexwBgwkq0TMuMeta?.path ?? "/en/news",
    meta: indexwBgwkq0TMuMeta || {},
    alias: indexwBgwkq0TMuMeta?.alias || [],
    redirect: indexwBgwkq0TMuMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZDB5obFmmBMeta?.name ?? "page-id___ru",
    path: _91id_93ZDB5obFmmBMeta?.path ?? "/page/:id()",
    meta: _91id_93ZDB5obFmmBMeta || {},
    alias: _91id_93ZDB5obFmmBMeta?.alias || [],
    redirect: _91id_93ZDB5obFmmBMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZDB5obFmmBMeta?.name ?? "page-id___en",
    path: _91id_93ZDB5obFmmBMeta?.path ?? "/en/page/:id()",
    meta: _91id_93ZDB5obFmmBMeta || {},
    alias: _91id_93ZDB5obFmmBMeta?.alias || [],
    redirect: _91id_93ZDB5obFmmBMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/[id].vue").then(m => m.default || m)
  },
  {
    name: corpora_45publicdap38ACCuyMeta?.name ?? "page-corpora-public___ru",
    path: corpora_45publicdap38ACCuyMeta?.path ?? "/page/corpora-public",
    meta: corpora_45publicdap38ACCuyMeta || {},
    alias: corpora_45publicdap38ACCuyMeta?.alias || [],
    redirect: corpora_45publicdap38ACCuyMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/corpora-public.vue").then(m => m.default || m)
  },
  {
    name: corpora_45publicdap38ACCuyMeta?.name ?? "page-corpora-public___en",
    path: corpora_45publicdap38ACCuyMeta?.path ?? "/en/page/corpora-public",
    meta: corpora_45publicdap38ACCuyMeta || {},
    alias: corpora_45publicdap38ACCuyMeta?.alias || [],
    redirect: corpora_45publicdap38ACCuyMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/corpora-public.vue").then(m => m.default || m)
  },
  {
    name: preview_45_91id_93Boz6gFDyGqMeta?.name ?? "page-preview-id___ru",
    path: preview_45_91id_93Boz6gFDyGqMeta?.path ?? "/page/preview-:id()",
    meta: preview_45_91id_93Boz6gFDyGqMeta || {},
    alias: preview_45_91id_93Boz6gFDyGqMeta?.alias || [],
    redirect: preview_45_91id_93Boz6gFDyGqMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/preview-[id].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91id_93Boz6gFDyGqMeta?.name ?? "page-preview-id___en",
    path: preview_45_91id_93Boz6gFDyGqMeta?.path ?? "/en/page/preview-:id()",
    meta: preview_45_91id_93Boz6gFDyGqMeta || {},
    alias: preview_45_91id_93Boz6gFDyGqMeta?.alias || [],
    redirect: preview_45_91id_93Boz6gFDyGqMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/page/preview-[id].vue").then(m => m.default || m)
  },
  {
    name: resultsEuMIblWEqwMeta?.name ?? "results___ru",
    path: resultsEuMIblWEqwMeta?.path ?? "/results",
    meta: resultsEuMIblWEqwMeta || {},
    alias: resultsEuMIblWEqwMeta?.alias || [],
    redirect: resultsEuMIblWEqwMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/results.vue").then(m => m.default || m)
  },
  {
    name: resultsEuMIblWEqwMeta?.name ?? "results___en",
    path: resultsEuMIblWEqwMeta?.path ?? "/en/results",
    meta: resultsEuMIblWEqwMeta || {},
    alias: resultsEuMIblWEqwMeta?.alias || [],
    redirect: resultsEuMIblWEqwMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/results.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DPOkalOwPDMeta?.name ?? "search-id___ru",
    path: _91id_93DPOkalOwPDMeta?.path ?? "/search/:id()",
    meta: _91id_93DPOkalOwPDMeta || {},
    alias: _91id_93DPOkalOwPDMeta?.alias || [],
    redirect: _91id_93DPOkalOwPDMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/search/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93DPOkalOwPDMeta?.name ?? "search-id___en",
    path: _91id_93DPOkalOwPDMeta?.path ?? "/en/search/:id()",
    meta: _91id_93DPOkalOwPDMeta || {},
    alias: _91id_93DPOkalOwPDMeta?.alias || [],
    redirect: _91id_93DPOkalOwPDMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/search/[id].vue").then(m => m.default || m)
  },
  {
    name: indexM4FSKfoqdYMeta?.name ?? "search___ru",
    path: indexM4FSKfoqdYMeta?.path ?? "/search",
    meta: indexM4FSKfoqdYMeta || {},
    alias: indexM4FSKfoqdYMeta?.alias || [],
    redirect: indexM4FSKfoqdYMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4FSKfoqdYMeta?.name ?? "search___en",
    path: indexM4FSKfoqdYMeta?.path ?? "/en/search",
    meta: indexM4FSKfoqdYMeta || {},
    alias: indexM4FSKfoqdYMeta?.alias || [],
    redirect: indexM4FSKfoqdYMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: site_45search2rMd202lHSMeta?.name ?? "site-search___ru",
    path: site_45search2rMd202lHSMeta?.path ?? "/site-search",
    meta: site_45search2rMd202lHSMeta || {},
    alias: site_45search2rMd202lHSMeta?.alias || [],
    redirect: site_45search2rMd202lHSMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/site-search.vue").then(m => m.default || m)
  },
  {
    name: site_45search2rMd202lHSMeta?.name ?? "site-search___en",
    path: site_45search2rMd202lHSMeta?.path ?? "/en/site-search",
    meta: site_45search2rMd202lHSMeta || {},
    alias: site_45search2rMd202lHSMeta?.alias || [],
    redirect: site_45search2rMd202lHSMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/site-search.vue").then(m => m.default || m)
  },
  {
    name: statsIymmAFjWijMeta?.name ?? "stats___ru",
    path: statsIymmAFjWijMeta?.path ?? "/stats",
    meta: statsIymmAFjWijMeta || {},
    alias: statsIymmAFjWijMeta?.alias || [],
    redirect: statsIymmAFjWijMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: statsIymmAFjWijMeta?.name ?? "stats___en",
    path: statsIymmAFjWijMeta?.path ?? "/en/stats",
    meta: statsIymmAFjWijMeta || {},
    alias: statsIymmAFjWijMeta?.alias || [],
    redirect: statsIymmAFjWijMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wJreN3LkcfMeta?.name ?? undefined,
    path: _91id_93wJreN3LkcfMeta?.path ?? "/subcorpus/:id()",
    meta: _91id_93wJreN3LkcfMeta || {},
    alias: _91id_93wJreN3LkcfMeta?.alias || [],
    redirect: _91id_93wJreN3LkcfMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id].vue").then(m => m.default || m),
    children: [
  {
    name: docsIubLyGQ9HcMeta?.name ?? "subcorpus-id-docs___ru",
    path: docsIubLyGQ9HcMeta?.path ?? "docs",
    meta: docsIubLyGQ9HcMeta || {},
    alias: docsIubLyGQ9HcMeta?.alias || [],
    redirect: docsIubLyGQ9HcMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/docs.vue").then(m => m.default || m)
  },
  {
    name: frequency_45dictionaryLb02dN50rGMeta?.name ?? "subcorpus-id-frequency-dictionary___ru",
    path: frequency_45dictionaryLb02dN50rGMeta?.path ?? "frequency-dictionary",
    meta: frequency_45dictionaryLb02dN50rGMeta || {},
    alias: frequency_45dictionaryLb02dN50rGMeta?.alias || [],
    redirect: frequency_45dictionaryLb02dN50rGMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/frequency-dictionary.vue").then(m => m.default || m)
  },
  {
    name: indexf6sO8bRbSnMeta?.name ?? "subcorpus-id___ru",
    path: indexf6sO8bRbSnMeta?.path ?? "",
    meta: indexf6sO8bRbSnMeta || {},
    alias: indexf6sO8bRbSnMeta?.alias || [],
    redirect: indexf6sO8bRbSnMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: stats18zS2LD21QMeta?.name ?? undefined,
    path: stats18zS2LD21QMeta?.path ?? "stats",
    meta: stats18zS2LD21QMeta || {},
    alias: stats18zS2LD21QMeta?.alias || [],
    redirect: stats18zS2LD21QMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats.vue").then(m => m.default || m),
    children: [
  {
    name: chronoJnQuRiFhmeMeta?.name ?? "subcorpus-id-stats-chrono___ru",
    path: chronoJnQuRiFhmeMeta?.path ?? "chrono",
    meta: chronoJnQuRiFhmeMeta || {},
    alias: chronoJnQuRiFhmeMeta?.alias || [],
    redirect: chronoJnQuRiFhmeMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/chrono.vue").then(m => m.default || m)
  },
  {
    name: indexJAmRfUbALMMeta?.name ?? "subcorpus-id-stats___ru",
    path: indexJAmRfUbALMMeta?.path ?? "",
    meta: indexJAmRfUbALMMeta || {},
    alias: indexJAmRfUbALMMeta?.alias || [],
    redirect: indexJAmRfUbALMMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91id_93wJreN3LkcfMeta?.name ?? undefined,
    path: _91id_93wJreN3LkcfMeta?.path ?? "/en/subcorpus/:id()",
    meta: _91id_93wJreN3LkcfMeta || {},
    alias: _91id_93wJreN3LkcfMeta?.alias || [],
    redirect: _91id_93wJreN3LkcfMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id].vue").then(m => m.default || m),
    children: [
  {
    name: docsIubLyGQ9HcMeta?.name ?? "subcorpus-id-docs___en",
    path: docsIubLyGQ9HcMeta?.path ?? "docs",
    meta: docsIubLyGQ9HcMeta || {},
    alias: docsIubLyGQ9HcMeta?.alias || [],
    redirect: docsIubLyGQ9HcMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/docs.vue").then(m => m.default || m)
  },
  {
    name: frequency_45dictionaryLb02dN50rGMeta?.name ?? "subcorpus-id-frequency-dictionary___en",
    path: frequency_45dictionaryLb02dN50rGMeta?.path ?? "frequency-dictionary",
    meta: frequency_45dictionaryLb02dN50rGMeta || {},
    alias: frequency_45dictionaryLb02dN50rGMeta?.alias || [],
    redirect: frequency_45dictionaryLb02dN50rGMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/frequency-dictionary.vue").then(m => m.default || m)
  },
  {
    name: indexf6sO8bRbSnMeta?.name ?? "subcorpus-id___en",
    path: indexf6sO8bRbSnMeta?.path ?? "",
    meta: indexf6sO8bRbSnMeta || {},
    alias: indexf6sO8bRbSnMeta?.alias || [],
    redirect: indexf6sO8bRbSnMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: stats18zS2LD21QMeta?.name ?? undefined,
    path: stats18zS2LD21QMeta?.path ?? "stats",
    meta: stats18zS2LD21QMeta || {},
    alias: stats18zS2LD21QMeta?.alias || [],
    redirect: stats18zS2LD21QMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats.vue").then(m => m.default || m),
    children: [
  {
    name: chronoJnQuRiFhmeMeta?.name ?? "subcorpus-id-stats-chrono___en",
    path: chronoJnQuRiFhmeMeta?.path ?? "chrono",
    meta: chronoJnQuRiFhmeMeta || {},
    alias: chronoJnQuRiFhmeMeta?.alias || [],
    redirect: chronoJnQuRiFhmeMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/chrono.vue").then(m => m.default || m)
  },
  {
    name: indexJAmRfUbALMMeta?.name ?? "subcorpus-id-stats___en",
    path: indexJAmRfUbALMMeta?.path ?? "",
    meta: indexJAmRfUbALMMeta || {},
    alias: indexJAmRfUbALMMeta?.alias || [],
    redirect: indexJAmRfUbALMMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/[id]/stats/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexTxpVuXwceWMeta?.name ?? "subcorpus___ru",
    path: indexTxpVuXwceWMeta?.path ?? "/subcorpus",
    meta: indexTxpVuXwceWMeta || {},
    alias: indexTxpVuXwceWMeta?.alias || [],
    redirect: indexTxpVuXwceWMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/index.vue").then(m => m.default || m)
  },
  {
    name: indexTxpVuXwceWMeta?.name ?? "subcorpus___en",
    path: indexTxpVuXwceWMeta?.path ?? "/en/subcorpus",
    meta: indexTxpVuXwceWMeta || {},
    alias: indexTxpVuXwceWMeta?.alias || [],
    redirect: indexTxpVuXwceWMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/subcorpus/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fq5e2Ck2AMMeta?.name ?? "word-id___ru",
    path: _91id_93fq5e2Ck2AMMeta?.path ?? "/word/:id()",
    meta: _91id_93fq5e2Ck2AMMeta || {},
    alias: _91id_93fq5e2Ck2AMMeta?.alias || [],
    redirect: _91id_93fq5e2Ck2AMMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/word/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93fq5e2Ck2AMMeta?.name ?? "word-id___en",
    path: _91id_93fq5e2Ck2AMMeta?.path ?? "/en/word/:id()",
    meta: _91id_93fq5e2Ck2AMMeta || {},
    alias: _91id_93fq5e2Ck2AMMeta?.alias || [],
    redirect: _91id_93fq5e2Ck2AMMeta?.redirect,
    component: () => import("/place/jenkins/workspace/deploy-front/client/pages/word/[id].vue").then(m => m.default || m)
  }
]